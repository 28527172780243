<template>
  <v-card tile :elevation="0" class="crud-box list-payments">
    <h1 class="mx-4">Hạch Toán Thu Chi</h1>
    <v-layout wrap class="px-4 pt-4">
      <v-layout wrap style="max-width: calc(100% - 250px);">
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search User Email" style="width: 120px;"
                      v-model="userEmail"/>
        <v-menu
          v-model="showCreatedDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Khoảng thời gian thống kê" class="mr-2" @click:clear="clearCreatedDateSearch()" clearable dense outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(createdDates)"
                          @click="showCreatedDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="createdDates" range></v-date-picker>
        </v-menu>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeader" :items="listItems" mobile-breakpoint="0" :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" :show-select="tblConfig.showSelect">

        <template v-slot:item.deposit="{item}">
          <money-format :value="item.deposit"
                        :hide-subunits=true :locale="'vi'"
                        :currency-code="'VND'"
                        style="display: inline-block;">
          </money-format>
        </template>
        <template v-slot:item.topup="{item}">
          <money-format :value="item.topup"
                        :hide-subunits=true :locale="'vi'"
                        :currency-code="'VND'"
                        style="display: inline-block;">
          </money-format>
        </template>
        <template v-slot:item.paid="{item}">
          <money-format :value="item.paid"
                        :hide-subunits=true :locale="'vi'"
                        :currency-code="'VND'"
                        style="display: inline-block;">
          </money-format>
        </template>
        <template v-slot:item.withdraw="{item}">
          <money-format :value="item.withdraw"
                        :hide-subunits=true :locale="'vi'"
                        :currency-code="'VND'"
                        style="display: inline-block;">
          </money-format>
        </template>
        <template v-slot:item.balance="{item}">
          <money-format :value="item.balance"
                        :hide-subunits=true :locale="'vi'"
                        :currency-code="'VND'"
                        style="display: inline-block;">
          </money-format>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>
<script>
import MoneyFormat from "vue-money-format";
import axios from "axios";

export default {
  name: 'ManagePayments',
  components: {MoneyFormat},
  data() {
    return {
      tblHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          groupable: false,
          width: '55px',
          align: 'start',
        },
        {
          text: 'User Email',
          value: 'user_email',
        },
        {
          text: 'Tổng số tiền nạp',
          value: 'deposit',
        },
        {
          text: 'Tổng số khuyến mãi',
          value: 'topup',
        },
        {
          text: 'Tiền trả order',
          value: 'paid',
        },
        {
          text: 'Tiền rút về',
          value: 'withdraw',
        },
        {
          text: 'Balance',
          value: 'balance',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          width: '111px',
          align: 'center',
        }
      ],
      showCreatedDatePicker: false,
      createdDates: [],
      pickedItems: [],
      selectedItem: null,
      idsToDelete: [],
      idsToApprove: null,
      tblLoading: false,
      querySilent: true,
      tblConfig: {
        footerProps: {
          showFirstLastPage: true,
          'items-per-page-options': [15, 25, 50, 100, -1]
        },
        showSelect: true,
        text: {
          loading: 'Loading ...',
          noItem: 'No item found'
        },
      },
      tblOptions: {},
      userEmail: '',
      dateFrom: '',
      dateTo: '',
      listItems: []
    }
  },
  computed: {
    DateRangeText() {
      return dateArr => {
        return dateArr.join(' ~ ')
      }
    },
  },
  methods: {
    async doSearch() {
      let searchParams = {};
      if (this.userEmail) searchParams.user_email = this.userEmail;
      if (this.dateFrom) searchParams.from_date = this.dateFrom;
      if (this.dateTo) searchParams.to_date = this.dateTo;
      let res = await axios.get('/payment_summaries', {params: searchParams});
      if (res.data && res.data.data) this.listItems = res.data.data;
    },
    clearCreatedDateSearch() {
      this.dateFrom = '';
      this.dateTo = '';
    },
  },
  watch: {
    createdDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.dateFrom = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.dateTo = val[1];
      },
      deep: true,
      immediate: true
    },
  }
}
</script>
